import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handleArchiveWidgetResponse } from '../aggregated-archive/actions';
import {
  getMonthsDisplayLimit,
  getShowNewestPostsFirst,
} from '../selectors/archive-selectors';
import type { ArchiveWidgetThunkAction } from '../types';

type FetchArchiveWidgetInitialDataArgs = {
  showNewestPostsFirst?: boolean;
  monthsDisplayLimit?: number;
};

export const fetchArchiveWidgetInitialData =
  ({
    showNewestPostsFirst,
    monthsDisplayLimit,
  }: FetchArchiveWidgetInitialDataArgs): ArchiveWidgetThunkAction =>
  async (dispatch, getState, { aggregatorRequest, wixCodeApi }) => {
    const state = getState();
    const langQuery = getQueryLocale(state);
    const language = langQuery ? `&language=${langQuery}` : '';
    const showNewestPostsFirstParamValue =
      typeof showNewestPostsFirst === 'boolean'
        ? showNewestPostsFirst
        : getShowNewestPostsFirst(state);
    const timeZone = wixCodeApi.site.timezone
      ? `&timeZone=${wixCodeApi.site.timezone}`
      : '';

    const monthsDisplayLimitParamValue =
      typeof monthsDisplayLimit === 'number'
        ? monthsDisplayLimit
        : getMonthsDisplayLimit(state);

    const path = `/v1/archive-widget/render-model?months=${monthsDisplayLimitParamValue}&showNewestPostsFirst=${showNewestPostsFirstParamValue}${timeZone}${language}`;
    const response = await aggregatorRequest(path, {
      throwOnInvalidJson: true,
    });

    if (response && typeof response === 'object' && 'archive' in response) {
      await dispatch(handleArchiveWidgetResponse(response.archive));
    } else {
      throw new Error('Invalid response');
    }
  };
