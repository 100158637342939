import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { wixDataReducer } from '@wix/communities-blog-client-common';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import commonReducers from '../../common/reducers';
import createRequests from '../../common/services/create-requests';
import createStore from '../../common/services/create-store';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import categories from '../../common/store/categories/categories-reducer';
import postCount from '../../common/store/post-count/post-count-reducer';
import { AppStore, ControllerConfig, FedopsLogger } from '../../common/types';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../constants/post-list-widget';
import { PostListWidgetThunkArgs } from '../types/post-list-widget-thunk-args';

interface CreateReduxStoreParams {
  controllerConfig: ControllerConfig;
  flowAPI: ControllerFlowAPI;
  fedopsLogger: FedopsLogger;
}

export function createReduxStore({
  controllerConfig,
  flowAPI,
  fedopsLogger,
}: CreateReduxStoreParams) {
  const { appParams, compId, wixCodeApi } = controllerConfig;
  const { isSSR } = getEnvironment(wixCodeApi);
  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);
  const p = {} as { store: AppStore };
  const getStore = () => p.store;

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    wixCodeApi,
    bundleName: POST_LIST_WIDGET_BUNDLE_NAME,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
      flowAPI,
    } satisfies PostListWidgetThunkArgs),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    wixData: wixDataReducer,
    postCount,
    categories,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
