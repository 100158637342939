import {
  MEMBERS_ABOUT_APP_ID,
  SANTA_MEMBERS_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createAction, uniqByKey } from '@wix/communities-blog-client-common';
import { MEMBERS_PROFILE_WIDGET_ID } from '../../../../common/members';
import { CommonThunkAction } from '../../../../types/common-thunk-action.type';
import { getIsMemberAreaInstalled } from '../communities-context/communities-context-selectors';
import { getProfileUrls } from './profile-urls-selectors';

interface MembersAPI {
  getSectionUrl: (params: {
    appDefinitionId: string;
    sectionId: string;
    widgetId: string;
    memberId: string | null | undefined;
    memberSlug: string | null | undefined;
  }) => Promise<string>;
}

export const setProfileUrls = createAction(
  'profileUrls/SET',
  (payload: Record<string, string>) => payload,
);

export const fetchProfileUrls =
  (): CommonThunkAction<void> =>
  async (dispatch, getState, { wixCodeApi }) => {
    const state = getState();
    if (!getIsMemberAreaInstalled(state)) {
      return;
    }

    const fetchedProfileUrls = getProfileUrls(state);
    const postOwnersUnsafe = Object.values(state.posts)
      .map((post) => post.owner)
      .filter((owner): owner is NonNullable<typeof owner> =>
        owner?.siteMemberId &&
        !fetchedProfileUrls[owner.siteMemberId] &&
        owner.privacyStatus !== 'PRIVATE'
          ? true
          : false,
      );
    const postOwners = uniqByKey(postOwnersUnsafe, 'siteMemberId');

    if (postOwners.length === 0) {
      return;
    }

    try {
      const api: MembersAPI = await wixCodeApi.site.getPublicAPI(
        SANTA_MEMBERS_APP_ID,
      );
      const idToProfileUrlEntries = await Promise.all(
        postOwners.map((owner) =>
          api
            .getSectionUrl({
              appDefinitionId: MEMBERS_ABOUT_APP_ID,
              sectionId: 'about',
              widgetId: MEMBERS_PROFILE_WIDGET_ID,
              memberId: owner.siteMemberId!,
              memberSlug: owner.slug,
            })
            .then((url) => [owner.siteMemberId!, url] as const),
        ),
      );

      const urlsMap: Record<string, string> = Object.fromEntries(
        idToProfileUrlEntries,
      );

      dispatch(setProfileUrls(urlsMap));
    } catch (error) {
      console.log(error);
    }
  };
